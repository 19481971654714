import React from 'react';
import Collapsible from 'react-collapsible';

import ArrowDown from './assets/arrow_down.svg';
import ArrowRight from './assets/arrow_right.svg';
import ArrowRightSilver from './assets/arrow_right_silver.svg';
import QuestionsContent from './questions';
import Label from './Label';

import { Content, Wrapper, Container, Title, SubLabel } from './styles';

export default function Questions() {
  const renderParagraphWithHyphen = description => {
    const [
      whithoutParagraph,
      firstParagraph,
      secondParagraph,
    ] = description.split('- ');

    return (
      <>
        <p style={{ marginBottom: 0 }}>{whithoutParagraph}</p>
        <p style={{ marginTop: '5px', marginBottom: '5px' }}>
          - {firstParagraph}
        </p>
        <p style={{ marginTop: '5px' }}>- {secondParagraph}</p>
      </>
    );
  };

  return (
    <Wrapper>
      {QuestionsContent.map((question, idx) => {
        return (
          <Container key={idx} id={question.id}>
            <Content>
              <Collapsible
                trigger={<Label title={question.label} icon={ArrowRight} />}
                triggerWhenOpen={
                  <Label title={question.label} icon={ArrowDown} />
                }
              >
                {question.content.map((c, idx) => (
                  <div key={idx} style={{ marginTop: '18px' }}>
                    {c.sublabel && <SubLabel>{c.sublabel}</SubLabel>}

                    <Title>
                      <img src={ArrowRightSilver} alt='Arrow right' />
                      <span>{c.title}</span>
                    </Title>

                    {c.hasLink ? (
                      <p>
                        Deverá enviar um{' '}
                        <a href='mailto:suporte.autocontrole@libertyseguros.com.br'>
                          e-mail
                        </a>{' '}
                        para o endereço
                        suporte.autocontrole@libertyseguros.com.br e mencionar
                        no corpo do e-mail sobre acuracidade do cálculo,
                        anexando junto a fatura em questão.{' '}
                      </p>
                    ) : c.title === 'Qual tipo de dispositivo é instalado?' ? (
                      renderParagraphWithHyphen(c.description)
                    ) : (
                      <p>{c.description}</p>
                    )}
                  </div>
                ))}
              </Collapsible>
            </Content>
          </Container>
        );
      })}
    </Wrapper>
  );
}
